<template>
  <b-container v-if="!productionBuild">
    <h1>Content Dashboard</h1>
    <b-form @submit="onSubmit"
        @reset="onReset">
        <b-form-row>
            <b-col class="cardForm">
                <b-card :img-src="card.thumbnail"
                    img-height="250px"
                    style="width:66%; margin-top:120px"
                    :class="!cardActive ? 'dashboardcard' : ''"
                    :title="!cardActive ? card.title : ''"
                    @click="focusCard"
                    v-click-outside="unfocusCard">
                    <b-card-text v-show="!cardActive">
                      {{card.description}}
                    </b-card-text>
                    <b-form-group v-show="cardActive">
                      <b-form-group label="Card Title">
                        <b-form-input v-model="card.title"
                        :state="cardTitleValidation"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Card Description">
                        <b-form-textarea v-model="card.description"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="Thumbnail">
                        <b-form-file v-model="thumbnailHolder"></b-form-file>
                      </b-form-group>
                    </b-form-group>
                </b-card>
              </b-col>
              <b-col>

                <!--Card Type-->
              <b-form-group label="Content type">
                <b-radio-group
                  v-model="card.type"
                  plain>
                  <b-form-radio :value="'Gallery'" id="radio1">Gallery</b-form-radio>
                  <b-form-radio :value="'Article'" id="radio2">Article</b-form-radio>
                </b-radio-group>
              </b-form-group>
              

              <!--Gallery subform-->
              <b-form-group v-if="card.type === 'Gallery'">
                <b-form-group label="Gallery title">
                  <b-form-input v-model="gallery.title"
                  :state="galleryTitleValidation"></b-form-input>
                </b-form-group>
                <b-form-group label="Gallery description">
                  <b-form-textarea v-model="gallery.description"></b-form-textarea>
                </b-form-group>
                <b-form-group label="Add image to Gallery">
                  <b-form-file v-model="imageHolder"
                  :placeholder="gallery.images.length + ' images selected'"
                  ref="galleryImageUploader"></b-form-file>
                </b-form-group>
                <div v-for="(img, imgIndex) in gallery.images"
                  :key="imgIndex"
                  class="imageContainer"
                  @click="removeImage(imgIndex)">
                  <b-img :src="img"
                  class="galleryImage"></b-img>
                  <div class="overlay">
                    <div class="overlayColor"></div>
                    <b-icon icon="trash-fill" class="deleteImage h3"></b-icon>
                  </div>
                </div>
              </b-form-group>

              <!--Article subform-->
              <!--TODO-->

              <!--Collections subform-->
              <b-form-group label="Collections">
                <b-form-row>
                  <b-col cols="10">
                    <b-form-select v-model="card.collections"
                      :options="collections"
                      value-field="id"
                      text-field="title"
                      multiple>
                    </b-form-select>
                  </b-col>
                  <b-col>
                    <b-button variant="outline-secondary" 
                    squared 
                    class="addToCollection"
                    @click="toggleCollectionForm">
                      <b-icon icon="folder-plus"></b-icon>
                    </b-button>
                  </b-col>
                </b-form-row>
              </b-form-group>
              <b-form-group v-if="collectionFormEnabled">
                <b-card>
                  <b-form @submit="createCollection">
                    <b-form-group label="Collection title">
                      <b-form-input v-model="collectionHolder.title">
                      </b-form-input>
                    </b-form-group>
                    <b-form-group label="Collection description">
                      <b-form-textarea v-model="collectionHolder.description">
                      </b-form-textarea>
                    </b-form-group>
                    <b-button style="float:right"
                      variant="primary"
                      :disabled="collectionFormValidation"
                      type="submit">
                      <b-icon icon="plus"></b-icon> Add
                    </b-button>
                  </b-form>
                </b-card>
              </b-form-group>


              <!--SUBMIT-->
              <b-button-group class="buttonContainer">
                <b-button class="formButton" type="submit" variant="primary" :disabled="!formValidation">Submit</b-button>
                <b-button class="formButton" type="reset" variant="primary">Reset</b-button>
                <b-button class="formbutton deletebutton" :disabled="card.id === ''" squared>
                  <b-icon icon="trash-fill" @click="deleteCard(card.id)"></b-icon>
                </b-button>
              </b-button-group>
            </b-col>
        </b-form-row>
        </b-form>
        <b-container v-if="cards.length > 0">
          <b-card-group fluid class="mx-0">
            <b-card
            v-for="c in cards" 
            :img-src="c.thumbnail" 
            :key="c.id" 
            img-height="250px" 
            class="dashboardcard previewcard"
            :title="c.title" @click="editCard(c)">
            <b-card-text>{{ c.description}}</b-card-text>
            </b-card>
          </b-card-group>
        </b-container>
  </b-container>
</template>

<script>
/*eslint-disable*/
import { mapState } from 'vuex';
import { IconsPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import VueGallery from 'vue-gallery';

Vue.use(IconsPlugin);
Vue.use(VueGallery);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

const newCard = () => {
  return {
    id: "",
    description: "Lorem Ipsum",
    contentId: "",
    thumbnail: "https://video.cgtn.com/news/2021-05-02/Wolong-National-Nature-Reserve-extracts-DNA-from-panda-droppings--ZVKNEVWE2A/video/5a8c73f8277a46b29cd14c5f1cdac0b9/5a8c73f8277a46b29cd14c5f1cdac0b9.jpg",
    collections: [], //Collections where the card is present
    title: "Example",
    type: "Gallery", //Gallery, Article, etc.
  }
};

const newGallery = () => {
  return {
    id: "",
    title: "",
    description: "",
    images: []
  }
}

const newCollection = () => {
  return {
    id: "",
    title: "",
    description: "",
  }
}

export default {
  data() {
    return {
      gallery : null,
      card: null,
      thumbnailHolder: null,
      cardActive: false,
      index: null,
      imageHolder: null,


      collectionHolder: {
        title: "",
        description: "",
        id: ""
      },
      collectionFormEnabled: false
    }
  },
  created() {
    this.resetForm();
    this.$store.dispatch("getCards");
    this.$store.dispatch("getCollections");
  },
  computed : {
    ...mapState(["cards", "collections", "productionBuild"]),
    cardTitleValidation() {
      return this.cards.filter(x => x.title === this.card.title && x.id !== this.card.id).length == 0;
    },
    galleryTitleValidation() {
      return this.gallery.title !== "";
    },
    collectionFormValidation() {
      return this.collections.filter(x => x.title === this.collectionHolder.title && x.id !== this.collectionHolder.id ).length == 0
        && (!this.collectionHolder.title || !this.collectionHolder.description);
    },
    formValidation() {
      return this.cardTitleValidation 
      && (this.galleryTitleValidation 
        && this.card.type === "Gallery")
      && this.card.type === "Gallery"; //because articles are not implemented yet
    }
  },
  methods : {
    async onSubmit(event) {
      event.preventDefault();
      if (!this.formValidation)
        return;
      if (this.card.type==="Gallery") {
        var content = await this.$store.dispatch("createOrUpdateGallery", this.gallery)

      } else if (this.card.type === "Article") {
        var content = await this.$store.dispatch("createOrUpdateArticle", this.article)
      }
      this.card.contentId = content.id;
      await this.$store.dispatch("createOrUpdateCard", this.card);
      this.resetForm();
    },
    onReset(event) {
      event.preventDefault();
      this.resetForm();
    },
    resetForm() {
      this.gallery = newGallery();
      this.card = newCard();
    },
    async createCollection(event) {
      event.preventDefault();
      var newCollectionId = await this.$store.dispatch("createCollection", this.collectionHolder);
      this.card.collections.push(newCollectionId);
      this.collectionHolder = newCollection();
    },
    toggleCollectionForm() {
      this.collectionFormEnabled = !this.collectionFormEnabled
      this.collectionHolder = newCollection();
    },
    showCollectionsForm(value) {
      this.collectionFormEnabled = value;
      this.collectionHolder = newCollection();
    },
    addContent() {
      //add either gallery or article to which the card links.
    },
    focusCard() {
      this.cardActive = true;
    },
    unfocusCard() {
      this.cardActive = false;
    },
    async editCard(card) {
      this.card = card;
      this.gallery = await this.$store.dispatch("getGallery", this.card.contentId)
    },
    deleteCard(id) {
      this.$store.dispatch("deleteCard", this.cards.find(x => x.id === id));
      this.resetForm();
    },
    removeImage(index) {
      this.gallery.images.splice(index, 1);
    }
  },
  watch: {
    thumbnailHolder(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.card.thumbnail = value;
            })
            .catch(() => {
              this.card.thumbnail = thumbnailPlaceholder;
            });
        } else {
          this.card.thumbnail = thumbnailPlaceholder;
        }
      }
    },
    imageHolder(newValue) {
      if (newValue) {
        base64Encode(newValue)
          .then(value => {
            this.gallery.images.push(value);
          })
        this.imageHolder = null;
        this.$refs.galleryImageUploader.reset();
      }
    }
  }
}
</script>

<style>
.addToCollection {
  float: right;
}
.previewcard {
  min-width: 24%;
  max-width: 24%;
}
.dashboardcard-body {
    padding: 10px;
  }
.cardForm {
    display: flex;
    justify-content: center;
    height: fit-content;
}
.dashboardcard:hover {
    outline: 5px solid #FF005D !important;
  }

.dashboardcardcard:active {
    outline-color: #fd74a6;
  }
.deletebutton{
  color: lightgray;
}
.deletebutton:hover:disabled {
  cursor: default;
}
.deletebutton:hover:enabled {
  color: white;
}
.deleteImage {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  align-self: center;
  color: white;
  float: center;
}
.galleryImage {
  height: 100%;
  width: 100%;
}
.imageContainer:hover .overlay {
  opacity: 1;
}
.imageContainer {
  width: 100px;
  height: 100px;
  position: relative;
  float: left;
  margin: 5px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
}
.overlayColor {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: .7;
  background-color: gray;
}
.buttonContainer {
  width: 100%;
}
.formButton {
  margin-right: 10px;
}
</style>
